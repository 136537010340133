import { createStore } from 'vuex'
import auth, { AuthState } from '@tenant/core/store/auth'
import textract, {
    TextractHightlightState,
} from '@tenant/core/store/textract-highlight'
import salutations, { SalutationState } from '@tenant/core/store/salutations'
import contactTypes, {
    ContactTypesState,
} from '@tenant/core/store/contact-types'
import addressTypes, {
    AddressTypesState,
} from '@tenant/core/store/address-types'
import accountTypes, {
    AccountTypesState,
} from '@tenant/core/store/account-types'
import textTemplateTypes, {
    TextTemplateState,
} from '@tenant/core/store/text-template'
import contactDetailTypes, {
    ContactDetailTypesState,
} from '@tenant/core/store/contact-detail-types'
import employees, { EmployeesState } from '@tenant/core/store/employees'
import departments, { DepartmentsState } from '@tenant/core/store/departments'

export type State = {
    auth: AuthState
    textract: TextractHightlightState
    salutations: SalutationState
    contactTypes: ContactTypesState
    addressTypes: AddressTypesState
    accountTypes: AccountTypesState
    textTemplateTypes: TextTemplateState
    employees: EmployeesState
    departments: DepartmentsState
    contactDetailTypes: ContactDetailTypesState
}

export default createStore<State>({
    modules: {
        auth,
        textract,
        salutations,
        contactTypes,
        addressTypes,
        accountTypes,
        textTemplateTypes,
        employees,
        departments,
        contactDetailTypes,
    },
})
